<template>
	<div id="contactUs" :class="[onlinestate=='ipad'?'ipad_class':onlinestate == 'phone'?'phone_class':'pc_class']">
		<div class='heard'>
			<div id='wrap_hand' class='wrap_hand wrap_hand_animation'>
				<div class='heard_left myun_down' @click="backClick()" style="cursor: pointer;"><img src="@/image/onhand/logo.png" alt=""></div>
				<div class='heard_left mydown'><img src="@/image/onhand/logo_pc.png" alt=""></div>
				<div class='heard_right mydown'><img src="@/image/onhand/icon.png" alt=""></div>
				<div class='heard_right myun_down'>
					<section class="mod model-1">
						<div class="menu" @click="menuPage()" :class="menuFlag ?'menu menu_hover':'menu'">
							<div class="bar"></div>
							<div class="bar"></div>
							<div class="bar"> </div>
						</div>
					</section>
				</div>
			</div>
        </div>
        <div>
			<div class="text_aboutUs">
				<div class="text_aboutUs_lxwm">联络我们</div>
				<div class="text_aboutUs_dl">
					<div style="display:flex;align-items:center;" @click="click_dq('dl')">
						<a-icon type="plus-circle" v-if="!dlFlag" />
						<a-icon type="minus-circle" v-else />
						<div style="margin-left:10px;font-size:18px;color: #40bfbf;">大连</div>
					</div>
					<!-- 10px 0px 0px 32px -->
					<div :style="{padding:onlinestate == 'pc' ?'20px 40px 0px':'10px 0px 0px 27px'}" v-if="dlFlag">
						<div>辽宁省大连市沙河口区西安路民勇大厦2118室</div>
						<div>电子邮箱：info@qmisit.com</div>
					</div>
				</div>
				<div class="text_aboutUs_xg">
					<div style="display:flex;align-items:center;" @click="click_dq('xg')">
						<a-icon type="plus-circle" v-if="!xgFlag"/>
						<a-icon type="minus-circle" v-else />
						<div style="margin-left:10px;font-size:18px;color: #40bfbf;">香港</div>
					</div>
					<div :style="{padding:onlinestate == 'pc' ?'20px 40px 0px':'10px 0px 0px 27px'}" v-if="xgFlag">
						<div v-if="onlinestate == 'phone'">
							<div>香港上环德辅道中199号无极限广场21楼</div>
							<div>2101室</div>
						</div>
						<div v-else>香港上环德辅道中199号无极限广场21楼2101室</div>
						<div>电子邮箱：info@qmisit.com</div>
					</div>
				</div>
				<div class="text_aboutUs_mkl">
					<div style="display:flex;align-items:center;" @click="click_dq('mkl')">
						<a-icon type="plus-circle" v-if="!mklFlag"/>
						<a-icon type="minus-circle" v-else />
						<div style="margin-left:10px;font-size:18px;color: #40bfbf;">马来西亚</div>
					</div>
					<div :style="{padding:onlinestate == 'pc' ?'20px 40px 0px':'10px 0px 0px 27px'}" v-if="mklFlag">
						<div>55-6, The Boulevard Office,</div>
						<div>Lingkaran Syed Putra, Mid Valley City,</div>
						<div>59200 Kuala Lumpur, Malaysia</div>
						<div>电子邮箱：info@qmisit.com</div>
					</div>
				</div>
			</div>
        </div>
        <div class="menu_div" v-if="menuFlag" @click="Move()">
			<div style="text-align: center;color: #ffffff;" class="menu_class">
				<div class="back_hover" style="cursor: pointer;" @click="skipClick(1)">主页</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(2)">关于我们</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(3)">服务范畴</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(4)">作品案例</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(5)">就业机会</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer; color:#40bfbf;" >联络我们</div>
			</div>
		</div>
		<cookie></cookie>
	</div>
</template>

<script>
	import AOS from "aos";
	import cookie from '../../cookies/cookie.vue'
	export default {
		components: {
			cookie
		},
		name: 'contactUs',
		data() {
			return {
				onlinestate:sessionStorage.onlinestate,
				menuFlag:false,
				dlFlag:true,
				xgFlag:true,
				mklFlag:true,
			}
		},
		mounted() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth' // 平滑滚动效果
			});
			// AOS.init();
			// // 你也可以在这里设置全局配置
			// AOS.init({
			// 	offset: 180,
			// 	duration: 400, //持续时间
			// 	easing: 'ease-in-sine',
			// 	delay: 300,
			// 	// once:true,
			// })
			// var scrolltop = '';
			// // let mybottomdom = '';
			// // setTimeout(()=>{
			// // 	 mybottomdom = document.getElementById('mybottom').offsetTop
			// // },500)
			// window.onscroll = function(e) {
			// 	this.isscrollnum = JSON.parse(JSON.stringify(e))
			// 	let island = 1.5
			// 	//判断scroll是否向上还是向下 比较当前的值是否比之前值的大小
			// 	if (scrolltop > this.isscroll) { //向下
			// 		island = 1.5
			// 		document.getElementById('wrap_hand').className = 'wrap_hand'
			// 	} else { //上
			// 		island = -1.5
			// 		document.getElementById('wrap_hand').className = 'wrap_hand wrap_hand_animation'
			// 	}
			// 	if (scrolltop < 50) {
			// 		document.getElementById('mytitle_img').className = 'mytitle_img'
			// 	} else if (scrolltop > 857) {
			// 		document.getElementById('mytitle_img').className = ' ';
			// 	}
			// 	this.isscroll = scrolltop;
			// 	scrolltop = document.documentElement.scrollTop;
			// }
		},
		methods: {
			backClick(){
				this.$router.push({ path: '/', query: { id: 0 }  });
			},
			click_dq(val){
				if (val == 'dl') {
					console.log("1");
					this.dlFlag = !this.dlFlag
					// this.xgFlag = false
					// this.mklFlag = false
				}
				if (val == 'xg') {
					console.log("2");
					// this.dlFlag = false
					this.xgFlag = !this.xgFlag
					// this.mklFlag = false
				}
				if (val == 'mkl') {
					console.log("3");
					// this.dlFlag = false
					// this.xgFlag = false
					this.mklFlag = !this.mklFlag
				}
			},
            skipClick(val){
                if (val == 1) {
                    this.$router.push({
						path: '/', 
						query: { id: 0 } 
					});
                }
                if (val == 2) {
                    this.$router.push({
						path: '/aboutUs', 
						query: { id: 2 } 
					});
                }
				if (val == 3) {
                    this.$router.push({
						path: '/', 
						query: { id: 3 } 
					});
                }
                if (val == 4) {
                    this.$router.push({
						path: '/', 
						query: { id: 4 } 
					});
                }
                if (val == 5) {
                    this.$router.push({
						path: '/aboutUs', 
						query: { id: 5 } 
					});
                }
            },
			menuPage(){
				console.log("菜單111111");
				this.menuFlag = !this.menuFlag
				if(this.menuFlag){
					this.stopMove()
				}else{
					this.Move()
				}
			},
			stopMove(){
				// let m = function(e){e.preventDefault();};
				// document.body.style.overflow='hidden';
				// document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
			},
			//开启页面滚动
			Move(){
				this.menuFlag = false
				let m =function(e){e.preventDefault();};
				document.body.style.overflow='';//出现滚动条
				document.removeEventListener("touchmove",m,{ passive:true });
			},

		}
	}
</script>
<style>
	body{
		color: #000;
		height: auto;
	}
</style>
<style lang='less' scoped>
	* {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		font-family: arial;
	}
	body {
		padding: 0;
		margin: 0;
		text-align: center;
		font-family: arial;
	}
	section {
		// height: 300px;
		// padding-top: 100px;
		float: left;
		width: 50%;
		position: relative;
		/*==============++++================*/
	}
	.menu {
		height: 28px;
		width: 28px;
		position: relative;
		margin: auto;
		// padding-top: 20px;
		border: 5px solid transparent;
		-moz-border-radius: 100%;
		-webkit-border-radius: 100%;
		border-radius: 100%;
		-moz-transition: 0.3s;
		-o-transition: 0.3s;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		cursor: pointer;
	}
	.bar {
		height: 3px;
		width: 28px;
		display: block;
		// margin: 10px auto;
		position: relative;
		background-color: #000;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		border-radius: 10px;
		-moz-transition: 0.4s;
		-o-transition: 0.4s;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}
	.model-1 .bar {
		position: absolute;
	}
	.model-1 .bar:nth-of-type(1) {
		// top: 15px;
		-moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
		-o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.3s, 0.1s;
		transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
		-moz-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .bar:nth-of-type(2) {
		top: 10px;
		-moz-transition: 0.3s ease 0.3s;
		-o-transition: 0.3s ease 0.3s;
		-webkit-transition: 0.3s ease;
		-webkit-transition-delay: 0.3s;
		transition: 0.3s ease 0.3s;
		-moz-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .bar:nth-of-type(3) {
		top: 20px;
		-moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
		-o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.3s, 0.1s;
		transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
		-moz-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .menu_hover .bar:nth-of-type(1) {
		top: 10px;
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		-moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
		-o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.1s, 0.5s;
		transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
	}
	.model-1 .menu_hover .bar:nth-of-type(2) {
		opacity: 0;
	}
	.model-1 .menu_hover .bar:nth-of-type(3) {
		top: 10px;
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		-moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
		-o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.1s, 0.5s;
		transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
	}
	.menu_class{
		font-size: 26px;
	}
	.anticon{
		font-size: 1.2rem;
		margin-top: 3px;
		color: #40bfbf;
	}
	.text_aboutUs_dl{
		margin-top: 50px;
		border-top: 2px solid #B9B8B7;
		padding: 40px 30px;
		text-align: left;
	}
	.text_aboutUs_xg{
		border-top: 2px solid #B9B8B7;
		padding: 40px 30px;
		text-align: left;
	}
	.text_aboutUs_mkl{
		border-top: 2px solid #B9B8B7;
		border-bottom: 2px solid #B9B8B7;
		padding: 40px 30px;
		text-align: left;
	}
	.text_aboutUs_lxwm{
		font-size: 25px;
		font-weight: 600;
	}
    .back_hover:hover{
        color: #40bfbf;
    }
	.home_bottoom{
		width: 90%;margin: 5px auto;
		display: flex;align-items: center;
		justify-content: space-between;
		height: 45px;
		padding: 10px 0;
	}
    .text_aboutUs{
        width: 50%;
        margin: 6rem  auto 30px;
		text-align: center;
    }
    .title_text{
        display: flex;
        align-items: center;
        font-weight: 600;
    }
    .title_text div{
        // margin-top: 5px;
        margin-right: 10px;
        width: 4px;
        height: 18px;
        background: #40bfbf;
    }
	.menu_div{
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}



	.mydown>img {
		width: 1rem;
		cursor: pointer;
	}

	.mydown {
		position: absolute;
		bottom: -8rem;
		left: 49.5%;
	}

	.mydown img:hover {
		margin-top: .5rem;
		transition: .3s ease-in-out;
	}


	.heard {
		background-color: #f5f5f5;
	}

	.contactUs {
		width: 100%;
		overflow: hidden;
	}



	.heard_left {
		float: left;
		padding: 10px;
	}
	.heard_left img {
		width: 17rem;
	}

	.heard_right {
		float: right;
		padding-top: 1.3rem;
	}

	.wrap_hand {
		/* display: inline-block; */
		overflow: hidden;
		position: absolute;
		width: 100%;
		/* left: 4%; */
		padding: 10px 4%;
		z-index: 100;
		background: rgba(255, 255, 255, 0.7);
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	}

	.wrap_hand_animations {
		position: fixed;
		top: 0;
	}

	.wrap_hand_animation {
		.heard_left {
			padding: 0;
		}

		.wrap_hand {
			padding: 8px 4%;
		}
	}
	
	.genlist{
		text-align: center;width: 25%;margin: 1rem 0 3rem;
	}
	.fagenlist{
		display: flex;justify-content: space-evenly;
	}
	
	.wrap_hand_animation {
		position: fixed;
		top: 0;
		-webkit-animation-name: hand-out;
		-moz-animation-name: hand-out;
		-o-animation-name: hand-out;
		animation-name: hand-out;
		-webkit-animation-duration: .5s;
		-moz-animation-duration: .5s;
		-o-animation-duration: .5s;
		animation-duration: .5s;
		background: rgba(255, 255, 255, .7);
	}

	.wrap_hand_animation img {
		-webkit-transition: padding 0.3s linear;
		-moz-transition: padding 0.3s linear;
		-o-transition: padding 0.3s linear;
		transition: padding 0.3s linear;
	}

	@keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-webkit-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-moz-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-o-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	.mytitle_img {
		-webkit-animation-name: zoom-out;
		-moz-animation-name: zoom-out;
		-o-animation-name: zoom-out;
		animation-name: zoom-out;
		-webkit-animation-duration: 10s;
		-moz-animation-duration: 10s;
		-o-animation-duration: 10s;
		animation-duration: 10s;
		width: 100vw;height: 101vh;
	}

	@-webkit-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@-moz-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@-o-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@media screen and(max-width:980px){
		.text_aboutUs{
			position: relative;
			top: 100px;
			margin-top: 0;
		}
		.text_aboutUs_dl{
			padding: 20px 15px;
		}
		.text_aboutUs_xg{
			padding: 20px 15px;
		}
		.text_aboutUs_mkl{
			padding: 20px 15px;
		}
		.anticon{
			font-size: initial;
		}
		.text_aboutUs{
			width: 90%;
		}
		.menu_class{
			font-size: 4.5rem;
		}

		.heard_left img {
			width: 63rem;
		}
		.heard_right{
			padding-top: 3.3rem;
			padding-right: 3.3rem;
		}
		.genlist img{
			width: 15rem;
		}
		.genlist{
			margin: 1rem 0 5.5rem;
		}
		/* .fagenlist{
			display: inline-block;
			justify-content: center;
			text-align: center;
		} */
		.myname{
			font-weight: 500;margin: 3.5rem 13px 0;font-size: 16px;color: #000000;
		}
		.mytitle_img {
			-webkit-animation-name: zoom-out;
			-moz-animation-name: zoom-out;
			-o-animation-name: zoom-out;
			animation-name: zoom-out;
			-webkit-animation-duration: 10s;
			-moz-animation-duration: 10s;
			-o-animation-duration: 10s;
			animation-duration: 10s;
			width: 100vw;
			height: 85rem;
		}

		.mydown{
			display: none;
		}
		.heard_left{
			padding: 0;
		}
	}
	.ipad_class{
		.heard_left img{
			width: 35rem;
		}
		.heard_right{
			padding-top: 2rem;
		}
		.menu_class{
			font-size: 26px !important;
		}
	}
</style>
